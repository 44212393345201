import Navstyle from "./Navbar.module.css";
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Login from "../../Pages/Landing/Login/Login";
import { useDispatch, useSelector } from "react-redux";
import { LogOutAction } from "./LoginAction/LogOutAction";
import { BenDataProps } from "../../Helper/BenInterface";

interface SubscriberData {
  subscriber: BenDataProps;
}

const Navbar: React.FC = () => {
  let SasToken = localStorage.getItem("sasToken") || "";

  const location = useLocation();
  const [loginModal, showLoginModal] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); 
  const { state } = location;
  const subscriberData = useSelector(
    (state: SubscriberData) => state.subscriber
  );
  const benificiaryData = useSelector((state: any) => state.beneficiary);
  const [isLargerThan768] = useMediaQuery("(min-width: 860px)");
  const [isMobile] = useMediaQuery("(max-width:480px)");

  const loginData = benificiaryData.filter((el: any) => {
    return subscriberData?._id === el._id;
  });   
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openModal = () => {
    showLoginModal(true);
  };

  const closeModal = () => {
    showLoginModal(false);
  };

  const logoutHandler = async () => {
    await LogOutAction(subscriberData.userId, dispatch);
  };

  const handleLogoClick = () => {
    if (!benificiaryData.length) {
      navigate("/");
    } else {
      navigate("/dashboard");
    }
  };

  const handleProfileClick = () => {
    if (subscriberData) return navigate(`/profile/view/${subscriberData._id}`);
  };

  const handleOrdersClick = () => {
    return navigate("/orders");
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle the menu's visibility
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  console.log('state:###################', location);
  return (
    <>
      <Box className={Navstyle.navbar} height={ isMobile ? "4.25rem":"5rem"} p={"1rem 2rem"} backgroundColor={ !isLargerThan768 ? "#F4F7F8" : "var(--white-color)"}>
        <Flex w={"100%"} alignItems={"center"} justifyContent={"space-between"}>
          <HStack className={Navstyle.navAlign}>
            <Box>
              {
              location.pathname.startsWith('/planDetails') ||  
              location.pathname.startsWith('/buysubscription') || 
              location.pathname.startsWith('/registration') || 
              location.pathname.startsWith('/subscription')
              ? 
              <Image
                cursor={"pointer"}
                // onClick={handleLogoClick}
                width={{ base: "125", md: "150" }}
                height={{ base: "45", md: "57" }}
                src="/Images/logo.png"
                alt="logo"
              />
              :
              <Image
                cursor={"pointer"}
                onClick={handleLogoClick}
                width={{ base: "125", md: "150" }}
                height={{ base: "45", md: "57" }}
                src="/Images/logo.png"
                alt="logo"
              />
            } 
            </Box>
          </HStack>

          {/* Home button more left than other nav items */}
          {isLargerThan768 && benificiaryData.length <= 0 && (
            <>
            {/* <Box textAlign={"center"}>
              <Text
                className={
                  location.pathname === "/" 
                    ? Navstyle.link_Style
                    : Navstyle.link_Style_opacity
                }
                // style={{ marginLeft: "auto" }} // Move Home to left
                onClick={() => navigate("/")}
              >
                Home
              </Text>
            </Box> */}
            <HStack flexBasis={"650px"} spacing={"3rem"} className={Navstyle.navAlign}>
              {/* Normal Links for larger screens */}
              <Text
                className={
                  location.pathname === "/" 
                    ? Navstyle.link_Style
                    : Navstyle.link_Style_opacity
                }
                onClick={() => navigate("/")}
              >
                Home
              </Text>
              <Text
                className={
                  location.pathname === "/buysubscription"
                    ? Navstyle.link_Style
                    : Navstyle.link_Style_opacity
                }
                style={{fontSize:"16px", fontWeight: "400", fontFamily: "Poppins"}}
                onClick={() => navigate("/buysubscription")}
              >
                Buy Subscription 
              </Text>
              <Text
                className={
                  location.pathname === "/about"
                    ? Navstyle.link_Style
                    : Navstyle.link_Style_opacity
                }
                onClick={() => navigate("/about")}
              >
                About
              </Text>
              <Text
                className={
                  location.pathname === "/careers"
                    ? Navstyle.link_Style
                    : Navstyle.link_Style_opacity
                }
                onClick={() => navigate("/careers")}
              >
                Careers
              </Text>
            
              { 
                !(
                    location.pathname.startsWith("/buysubscription") ||
                    location.pathname.startsWith("/registration") ||
                    location.pathname.startsWith("/registration/verify-otp")
                ) && (
                    <Button
                        className={Navstyle.button_Style}
                        onClick={openModal}
                        backgroundColor="#01A7B5"
                        color="white"
                        borderRadius="md"
                        padding="0.5rem 1.5rem"
                        _hover={{ bg: "teal.600" }}
                    >
                        Login
                    </Button>
                )
            }
            </HStack>
            </>
          )}
          {(isMobile && state?.showButton &&!isLargerThan768) &&
             
             <Button
             className={Navstyle.button_Style}
             style={{fontSize:"14px", fontWeight: "400", fontFamily: "Poppins"}}
             onClick={openModal}
             backgroundColor="#3182CE" // Same color for Login button
             color="white"
             borderRadius="md"
             padding="0.5rem 1rem"
             _hover={{
               backgroundColor: "#2C5282",
             }}
             >
             Login
             </Button>
           
          }

          {/* Hamburger Menu for screens <= 768px */}
          {!isLargerThan768 && benificiaryData.length <= 0 && (
            <>
              <Box cursor="pointer" onClick={toggleMenu}>
                {/* Custom Hamburger Icon */}
                <Box
                  as="span"
                  display="block"
                  w="25px"
                  h="2.5px"
                  mb="5px"
                  bg="#293A41"
                  borderRadius="2px"
                ></Box>
                <Box
                  as="span"
                  display="block"
                  w="25px"
                  h="2.5px"
                  mb="5px"
                  bg="#293A41"
                  borderRadius="2px"
                ></Box>
                <Box
                  as="span"
                  display="block"
                  w="25px"
                  h="2.5px"
                  mb="5px"
                  borderRadius="2px"
                  bg="#293A41"
                ></Box>
              </Box>
              {/* Dropdown menu */}
              {menuOpen && (
                <VStack
                  position="absolute"
                  top="60px"
                  right="0"
                  backgroundColor="white"
                  width="100%"
                  spacing={2.5}
                  boxShadow="md"
                  p={4}
                  zIndex={1000}
                >
                  <Text
                    className={Navstyle.link_Style}
                    style={{fontSize:"14px", fontWeight: "400", fontFamily: "Poppins"}}
                    onClick={() => {
                      navigate("/");
                      setMenuOpen(false); // Close menu after navigation
                    }}
                  >
                    Home
                  </Text>
                  <Text
                    className={Navstyle.link_Style}
                    style={{fontSize:"14px", fontWeight: "400", fontFamily: "Poppins"}}
                    onClick={() => navigate("/buysubscription")}
                  >
                    Buy Subscription 
                  </Text>
                  <Text
                    className={Navstyle.link_Style}
                    style={{fontSize:"14px", fontWeight: "400", fontFamily: "Poppins"}}
                    onClick={() => {
                      navigate("/about");
                      setMenuOpen(false);
                    }}
                  >
                    About
                  </Text>
                  <Text
                    className={Navstyle.link_Style}
                    style={{fontSize:"14px", fontWeight: "400", fontFamily: "Poppins"}}
                    onClick={() => {
                      navigate("/careers");
                      setMenuOpen(false);
                    }}
                  >
                    Careers
                  </Text>
                  <Button
                    className={Navstyle.button_Style}
                    style={{fontSize:"14px", fontWeight: "400", fontFamily: "Poppins"}}
                    onClick={openModal}
                    backgroundColor="#3182CE" // Same color for Login button
                    color="white"
                    borderRadius="md"
                    padding="0.5rem 1rem"
                    _hover={{
                      backgroundColor: "#2C5282",
                    }}
                  >
                    Login
                  </Button>
                </VStack>
              )}
            </>
          )}

          {benificiaryData.length > 0 &&  (
            <HStack className={Navstyle.navAlign}>
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                  isDisabled = {location.pathname.startsWith('/planDetails') || 
                                location.pathname.startsWith('/buysubscription') || 
                                location.pathname.startsWith('/registration') || 
                                location.pathname.startsWith('/subscription') ? true : false}
                >
                  <Avatar
                    style={{ marginLeft: "10px" }}
                    size={"sm"}
                    src={
                      loginData
                        ? loginData[0]?.personalInfo?.image + "?" + SasToken
                        : ""
                    }
                  />
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
                  <MenuItem onClick={handleOrdersClick}>Orders</MenuItem>
                  <MenuItem onClick={logoutHandler}>Logout</MenuItem>
                </MenuList>

              </Menu>
            </HStack>
          )}
        </Flex>
      </Box>

      <Login loginModal={loginModal} onClose={closeModal} />
    </>
  );
};

export default Navbar;
