import  { FC, ReactElement } from 'react'
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ShowAlert } from '../../GlobalComponents/ShowAlert/ShowAlert';

const CheckIsLoggedIn:FC<{children:ReactElement}> = ({children}) => {  
    const subscriberData = useSelector(
        (state: any) => state.subscriber
      );
      const userData = useSelector((state: any) => state.user);
      const beneficiaryData = useSelector(
        (state: any) => state.beneficiary
      );
      console.log('userData:', userData,beneficiaryData,subscriberData)
      if(Object.keys(userData).length>0&&beneficiaryData.length&&Object.keys(subscriberData).length>0){
        return children
      }
      ShowAlert("warning","Qr mapping is Unsuccessful Login And Map");
      return <Navigate to="/" state={{showButton:true}}/>;   
}

export default CheckIsLoggedIn
